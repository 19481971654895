(function($) {

    $(window).on('load', function () {

        // Components loading animations
        $('.view-animation').viewportChecker({
            classToAdd: 'animated',
            offset: 20
        });
        
        $('.header-hamburger').click(function(){
            $('body').toggleClass('menu-open');
        });


        var _paged = 1;
        $('#load-more').click(function(e){
            e.preventDefault();

            var $this = $(this);

            $this.text('Loading...');

            _paged++;

            $.ajax({
                url: _ajaxurl,
                type: 'post',
                data: {
                    action: 'more_posts',
                    paged: _paged
                }
            }).done(function(data){
                var response = JSON.parse(data);
                $('#ajax-append').append(response.html);
                $this.text('Load More Blogs');

                if(response.noneleft){
                    $this.hide();
                }
            });
        }); 

        // Phone Concatenation Script For Tracking
        setTimeout(function () {
            $('.phone-text em').each(function () {
                var unsliced = $(this).text();
                var sliced = unsliced.slice(0, -2) + "...";
                $(this).text(sliced);
                var linked = "tel:" + unsliced.replace(/\s/g, '');
                $(this).click(function () {
                    if ($(window).width() < 1000) {
                        window.location.href = linked;
                    } else {
                        $(this).text(unsliced);
                    }
                });
            });

        }, 3000);

        // get all sliders, we need to loop them due to different settings + nav
        var swipers = document.querySelectorAll('.swiper-container:not(.control):not(.mobile)');
        swipers.forEach(function(el,index){
            var closestSection = el.closest('section');
            var controls = closestSection.querySelector('.control');

            // slider settings
            var options = {
                speed: 600,
                loop: true,
                slidesPerView: 1,
                autoplay: {
                    delay: 5000,
                    disableOnInteraction: true,
                },
                navigation:{},
                thumbs:{},
            };

            if(el.hasAttribute('data-dots')){
                options.pagination = {
                    el: '.swiper-pagination',
                    type: 'bullets',
                    clickable: true
                };
            }

            if(el.hasAttribute('data-slides')){
                options.slidesPerView = 1;
                options.spaceBetween = 11;
                options.breakpoints = {
                    768: {
                       slidesPerView: 2
                    },
                    1170: {
                       slidesPerView: el.getAttribute('data-slides')
                    }
                };
            }

            // init nav
            options.navigation = {
                nextEl: closestSection.querySelector('.swiper-next'),
                prevEl: closestSection.querySelector('.swiper-prev')
            }

            // maybe add gallery controls to the slider
            if(controls){
                options.thumbs.swiper = new Swiper(controls, {
                    speed: 600,
                    loop: true,
                    slidesPerView: 4,
                });
            }

            // init slider
            new Swiper(el, options);
        });

        // mobile sliders, like logo rows etc
        // need to loop in order to get the slide count
        var mobileSwipers = document.querySelectorAll('.swiper-container.mobile');
        mobileSwipers.forEach(function(el,index){
            var closestSection = el.closest('section');
            var slideCount = el.querySelectorAll('.swiper-slide').length;

            var options = {
                speed:600,
                slidesPerView: 2,
                watchOverflow: true,
                loop: true,
                simulateTouch: false,
                pagination:{
                    el: '.swiper-pagination',
                    type: 'bullets',
                    clickable: true
                },
                breakpoints: {
                    576 : {
                        slidesPerView: 3
                    },
                    768 : {
                        slidesPerView: 4
                    },
                    992: {
                        slidesPerView: slideCount,
                    }
                }
            };

            // init slider
            new Swiper(el, options);
        });
    });

    $('.tab__sidebar__item').click(function(e){
        e.preventDefault();

        var thistab = $(this).data('tabtitle');

        $('.tab__sidebar__item.active').removeClass('active');
        $('.tab__content__item.active').removeClass('active');


        $(this).addClass('active');
        $('.tab__content__item[data-tabcontent="'+thistab+'"]').addClass('active');

        if(window.matchMedia('(max-width:767px)').matches){
            $('html,body').animate({scrollTop:$('.tab__content').offset().top - $('.site-header__top').outerHeight()}, 600);
        }

    });

    // Sticky Header
    $(window).on("scroll load", function () {
        if ($(window).scrollTop() >= 50) {
            $('.site-header').addClass('scrolled');
        } else {
            $('.site-header').removeClass('scrolled');
        }
    });

    //  form thankyou redirects
    var wpcf7Elm = document.querySelector( '.wpcf7' );
    if(wpcf7Elm){
        wpcf7Elm.addEventListener( 'wpcf7mailsent', function( event ) {
            if(wpcf7Elm.querySelector('[name="redirect"]')){
                var redirect = wpcf7Elm.querySelector('[name="redirect"]').value;
                window.location.href = redirect;
            }
        }, false );
    }

    // Smooth Scroll To Anchor
    $(document).on('click', 'a[href*="#"]:not([href="#"])', function (event) {
        event.preventDefault();
        var target = '#'+$(this).attr('href').split('#')[1];

        if ($(target).length) {

            $('html, body').animate({
                scrollTop: $(target).offset().top - $('.site-header__top').outerHeight() + 56
            }, 1500)
        }else{
            window.location.href = $(this).attr('href');
        }
    });

})( jQuery );